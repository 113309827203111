import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import axios from 'axios'
import 'foundation-sites/dist/css/foundation.min.css'
import 'foundation-sites/dist/css/foundation-prototype.min.css'
import './registerServiceWorker'
import Vue2TouchEvents from 'vue2-touch-events'
import VueSmoothScroll from 'vue2-smooth-scroll'
import * as klaro from 'klaro'
import VueMatomo from 'vue-matomo'



Vue.config.productionTip = false

Vue.prototype.$store = store
Vue.prototype.$http = axios
Vue.prototype.$klaro = klaro


Vue.use(VueSmoothScroll)
Vue.use(Vue2TouchEvents)

Vue.use(VueMatomo, {
  host: '//matomo.dia-vorsorge.de',
  siteId: 12,

  // Changes the default .js and .php endpoint's filename
  // Default: 'matomo'
  trackerFileName: 'matomo',

  // Overrides the autogenerated tracker endpoint entirely
  // Default: undefined
  // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

  // Overrides the autogenerated tracker script path entirely
  // Default: undefined
  // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

  // Enables automatically registering pageviews on the router
  router: router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,
  trackInitialView: true,
  disableCookies: true,

  // Require consent before creating matomo session cookie
  // Default: false
  requireCookieConsent: true,
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: "app.thrifter.de",
  preInitActions: [],
  trackSiteSearch: false,
  crossOrigin: undefined,
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
