<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <!-- <router-view/> -->
    <!-- if navigator is desktop, add iframe inside phone_image.png -->
    <!-- <div id="phone_image">
      <iframe src="https://www.youtube.com/embed/7GK_y2Qm7lg" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
    </div> -->

    <!-- <h1>Thrifter Showcase</h1> -->
    <div class="phone-body" v-if="!isMobile">
      <div class="buttons-left">
        <div class="volume-button"></div>
        <div class="bixby-button"></div>
      </div>

      <div class="sensors">
        <div class="speaker"></div>
        <div class="camera"></div>
        <div class="sensor"></div>
      </div>
      <div class="phone-screen">
        <iframe v-if="store.brokerData.slug" :src="store.state.iframeUrl+'/#/'+store.brokerData.slug"></iframe>
        <iframe v-else :src="store.state.iframeUrl"></iframe>
      </div>
      <div class="buttons-right">
        <div class="power-button"></div>
      </div>
    </div>
    <!-- <vklaro ref="vklaro" /> -->
    <router-view v-if="isMobile"/>

  </div>
</template>

<script>
// import vklaro from "@/components/VKlaro.vue"

export default {
  name: 'ThrifterApp',
  components: {
    // vklaro
  },
  data() {
    return {
      store: this.$store,
      isMobile: null,
    }
  },
  mounted() {
    this.checkMobile();
    // slug from url
    const slug = this.$route.params.slug;
    console.warn({ slug });
    if (slug) {
      // console.warn({ slug });
      this.store.brokerData.slug = slug;
      this.getbrokerData(slug)
    }
  },
  methods: {
    checkMobile() {
      if (window.innerWidth < 768) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    async getbrokerData(slug) {
      console.warn({slug});
      try {
        let data = await this.$http.get(`https://backend.thrifter.de/filterUserWithSlug/${slug}`)
        if (typeof data.data !== 'object') {
          console.error('Slug not found!');
          this.store.brokerData.slug = null;
          return;
        }

        let userData = data.data;
        
        this.store.brokerData.firstName = userData.first_name;
        this.store.brokerData.lastName = userData.last_name;
        this.store.brokerData.email = userData.email;
        this.store.brokerData.phone = userData.phone;
        this.store.brokerData.comapnyName = userData.company;
        this.store.brokerData.website = userData.website;
        this.store.brokerData.message = userData.description; 
        this.store.brokerData.logo = 'https://backend.thrifter.de/assets/'+userData.logo;

      } catch (error) {
        // console.error(error);
        console.error('Slug not found!');
        this.store.brokerData.slug = null;
      }
    }
  },
}
</script>

<style lang="stylus">
@font-face {
  font-family: 'Hind';
  src: url('/static/fonts/Hind-Regular.woff2') format('woff2'),
       url('/static/fonts/Hind-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Hind';
  src: url('/static/fonts/Hind-Light.woff2') format('woff2'),
       url('/static/fonts/Hind-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Hind';
  src: url('/static/fonts/Hind-Medium.woff2') format('woff2'),
       url('/static/fonts/Hind-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Hind';
  src: url('/static/fonts/Hind-SemiBold.woff2') format('woff2'),
       url('/static/fonts/Hind-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Hind';
  src: url('/static/fonts/Hind-Bold.woff2') format('woff2'),
       url('/static/fonts/Hind-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}


* {
  user-select: none;
}

::-webkit-scrollbar {
  width: 0; 
  background: transparent;
}

html
  scrollbar-width: none
  // overflow: hidden

#app
  text-align center
  color #300A1C
  width 100%
  height 100%
  font-family "Hind",Arial,Helvetica,sans-serif

body {
  margin 0
  padding 0
  width 100vw
  height 100vh
  background #0D0E21
  // overflow-y: scroll
}

a{
  cursor pointer
}

.slide-in-enter-active {
  transition: all .3s ease;
}
.slide-in-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-in-enter, .slide-in-leave-to {
  transform: translateY(15vw);
  opacity: 0;
}

html,
// body {
//   width: calc(100vw - 20px);
//   height: calc(100vh - 20px);
//   background: #25313f;
//   position: absolute;
//   left: 0;
//   top: 0;
// }
h1 {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  color: #fff;
}

.phone-body {
  background: #333;
  // height: 600px;
  // width: 320px;
  // height: 80vh;
  width:  375px;
  border-radius: 20px;
  margin: auto;
  padding: 25px 0px;
  position: relative;
  -webkit-box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.3);
  aspect-ratio: 9/19.5;
  // transform: scale(.9)
  max-height: 95vh;
  top: 50%;
  transform: translateY(-50%);
}

.volume-button {
  background: #404040;
  width: 3px;
  height: 70px;
  position: absolute;
  left: -3px;
  top: 60px;
  border-radius: 5px 0px 0px 5px;
}
.bixby-button {
  background: #404040;
  width: 3px;
  height: 30px;
  position: absolute;
  left: -3px;
  top: 160px;
  border-radius: 5px 0px 0px 5px;
}
.speaker {
  background: #686868;
  width: 30px;
  height: 5px;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.camera {
  background: #686868;
  width: 12px;
  height: 12px;
  border-radius: 20px;
  position: absolute;
  top: 10px;
  right: 25%;
  transform: translatey(-50%);
}
.sensor {
  background: #686868;
  width: 7.5px;
  height: 7.5px;
  border-radius: 20px;
  position: absolute;
  top: 10px;
  right: 20%;
  transform: translatey(-50%);
}

.phone-screen {
  height: 100%;
  width: 95%;
  background: #1e262f;
  margin: auto;
  border-radius: 10px;
}
.power-button {
  background: #404040;
  width: 3px;
  height: 30px;
  position: absolute;
  right: -3px;
  top: 130px;
  border-radius: 0px 5px 5px 0px;
}
.phone-screen iframe {
  width: 100%;
  height: 100%;
  border-radius: 9px;
  border: 0px;
}

.klaro .cookie-modal .cm-list-label .slider.round::before {
  transform: translate(0px, 10px)!important
}

.klaro .cookie-modal .cm-list-input:checked+.cm-list-label .slider::before,  {
  transform: translate(20px, 10px)!important
}

</style>
